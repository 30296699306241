import { ReactElement } from 'react';
import { capitalize } from 'lodash';
import SVG from 'react-inlinesvg';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import Typography from '../Typography';

import { TestimonyCardrops, TestimonyVariant } from './types';

import styles from './styles.module.scss';

const useUtilityClasses = (variant: TestimonyVariant) => {
  const slots = {
    root: [
      styles.testimonyCardRoot,
      styles[`testimonyCardVariant${capitalize(variant)}`],
    ],
    content: [styles.testimonyCardContent],
    contentIcon: [styles.testimonyCardContentIcon],
    contentInfo: [styles.testimonyCardContentInfo],
    contentInfoTestimony: [styles.testimonyCardContentInfoTestimony],
    contentInfoTestimonyTitle: [styles.testimonyCardContentInfoTestimonyTitle],
    contentInfoTestimonyDescription: [
      styles.testimonyCardContentInfoTestimonyDescription,
    ],
    contentInfoUser: [styles.testimonyCardContentInfoUser],
    contentInfoUserName: [styles.testimonyCardContentInfoUserName],
    contentInfoUserOccupation: [styles.testimonyCardContentInfoUserOccupation],
    media: [styles.testimonyCardMedia],
  };

  return composeClasses(slots);
};

const TestimonyCard = ({
  testimony,
  user,
  variant,
}: TestimonyCardrops): ReactElement => {
  const classes = useUtilityClasses(variant);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <SVG
          src={toAbsoluteUrl('/icons/tether-color.svg')}
          title="KM Isologo"
          className={classes.contentIcon}
        />

        <div className={classes.contentInfo}>
          <div className={classes.contentInfoTestimony}>
            <Typography
              component="h3"
              className={classes.contentInfoTestimonyTitle}
            >
              {testimony.title}
            </Typography>

            <Typography
              component="p"
              className={classes.contentInfoTestimonyDescription}
            >
              {testimony.description}
            </Typography>
          </div>

          <div className={classes.contentInfoUser}>
            <Typography component="h5" className={classes.contentInfoUserName}>
              {user.name}
            </Typography>

            <Typography
              component="p"
              className={classes.contentInfoUserOccupation}
            >
              {user.occupation}
            </Typography>
          </div>
        </div>
      </div>

      <img src={user.imageUrl} alt={user.name} className={classes.media} />
    </div>
  );
};

export default TestimonyCard;
