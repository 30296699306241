import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import Typography from '../Typography';
import Link from '../Link';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.heroRoot],
    content: [styles.heroContent],
    contentHeader: [styles.heroContentHeader],
    contentHeaderLogo: [styles.heroContentHeaderLogo],
    contentHeaderTitle: [styles.heroContentHeaderTitle],
    contentDescription: [styles.heroContentDescription],
    contentLink: [styles.heroContentLink],
    polygonBg: [styles.heroPolygonBg],
  };

  return composeClasses(slots);
};

const Hero = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useUtilityClasses();

  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <SVG
            src={toAbsoluteUrl('/icons/tether-white.svg')}
            title="Tether White"
            className={classes.contentHeaderLogo}
          />

          <Typography component="h1" className={classes.contentHeaderTitle}>
            {t('hero.title')}
          </Typography>
        </div>

        <Typography component="h4" className={classes.contentDescription}>
          {t('hero.description')}
        </Typography>

        <Link
          variant="normal"
          size="medium"
          href="https://www.kriptonmarket.com/broker?operation=cash-in&medium=Banco"
          target="blank"
          rel="noopener noreferrer"
          className={classes.contentLink}
        >
          {t('common.get-your-fist-usdt')}
        </Link>
      </div>

      <SVG
        src={toAbsoluteUrl('/images/bg-polygon-1.svg')}
        title="Background Polygon 1"
        className={classes.polygonBg}
      />
    </section>
  );
};

export default Hero;
