import { ReactElement } from 'react';

import {
  Container,
  Hero,
  ActionPresentation,
  DetailsSection,
  TestimonialsSection,
  QuestionsSection,
  Footer,
} from './components';

const App = (): ReactElement => (
  <Container>
    <Hero />

    <ActionPresentation />

    <DetailsSection />

    <TestimonialsSection />

    <QuestionsSection />

    <Footer />
  </Container>
);

export default App;
