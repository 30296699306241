import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import SVG from 'react-inlinesvg';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import Typography from '../Typography';
import TestimonyCard from '../TestimonyCard';
import Link from '../Link';
import { TESTIMONIALS_INFO } from './data';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.testimonialsSectionRoot],
    title: [styles.testimonialsSectionTitle],
    slider: [styles.testimonialsSectionSlider],
    link: [styles.testimonialsSectionLink],
    polygonBg: [styles.testimonialsSectionPolygonBg],
  };

  return composeClasses(slots);
};

const TestimonialsSection = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useUtilityClasses();

  return (
    <section className={classes.root}>
      <Typography component="h2" className={classes.title}>
        {t('testimonials.title')}
      </Typography>

      <div className={classes.slider}>
        <Slider
          adaptiveHeight
          arrows={false}
          // autoplay
          autoplaySpeed={10000}
          dots
          infinite
          pauseOnDotsHover
          pauseOnHover
          slidesToScroll={1}
          slidesToShow={1}
          speed={800}
          swipe={false}
          swipeToSlide={false}
        >
          {TESTIMONIALS_INFO.map(({ id, variant }) => (
            <TestimonyCard
              key={`testimony-${id}`}
              testimony={{
                title: t(`testimonials.${id}.testimony.title`),
                description: t(`testimonials.${id}.testimony.description`),
              }}
              user={{
                name: t(`testimonials.${id}.user.name`),
                occupation: t(`testimonials.${id}.user.occupation`),
                imageUrl: toAbsoluteUrl(`/testimonials-users/${id}.png`),
              }}
              variant={variant}
            />
          ))}
        </Slider>
      </div>

      <Link
        href="https://www.kriptonmarket.com/broker?operation=cash-in&medium=Banco"
        target="blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        {t('common.get-your-fist-usdt')}
      </Link>

      <SVG
        src={toAbsoluteUrl('/images/bg-polygon-2.svg')}
        title="Background Polygon 2"
        className={classes.polygonBg}
      />
    </section>
  );
};

export default TestimonialsSection;
