import { Testimony } from '../TestimonyCard/types';

export const TESTIMONIALS_INFO: Pick<Testimony, 'id' | 'variant'>[] = [
  { id: 'simoneth-gomez', variant: 'one' },
  { id: 'carlos-caballero', variant: 'two' },
  { id: 'alejandro-fernandes', variant: 'three' },
  { id: 'dubraska-villanloga', variant: 'one' },
  { id: 'ivan-orduña', variant: 'two' },
  { id: 'gabriel-campos', variant: 'three' },
  { id: 'heloisa-passos', variant: 'one' },
  { id: 'janine-rodrigues', variant: 'two' },
];
