import { ReactElement } from 'react';
import classnames from 'classnames';
import { capitalize } from 'lodash';

import { composeClasses } from '~utils/commons';

import { LinkProps } from './types';

import styles from './styles.module.scss';

const useUtilityClasses = ({
  variant,
  size,
}: Required<Pick<LinkProps, 'variant' | 'size'>>) => {
  const slots = {
    root: [
      styles.linkRoot,
      styles[`linkVariant${capitalize(variant)}`],
      styles[`linkSize${capitalize(size)}`],
    ],
  };

  return composeClasses(slots);
};

const Link = ({
  variant = 'normal',
  size = 'medium',
  className,
  ...rest
}: LinkProps): ReactElement => {
  const classes = useUtilityClasses({
    variant,
    size,
  });

  return <a className={classnames(classes.root, className)} {...rest} />;
};

export default Link;
