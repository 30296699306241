import { ReactElement } from 'react';
import classnames from 'classnames';

import { composeClasses } from '~utils/commons';

import { ContianerProps } from './types';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.containerRoot],
  };

  return composeClasses(slots);
};

const Container = ({ className, children }: ContianerProps): ReactElement => {
  const classes = useUtilityClasses();

  return (
    <main className={classnames(classes.root, className)}>{children}</main>
  );
};

export default Container;
