import { ReactElement } from 'react';

import { composeClasses } from '~utils/commons';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.wrappedSpinner],
    spinnerLoading: [styles.spinner],
  };

  return composeClasses(slots);
};

const Spinner = (): ReactElement => {
  const classes = useUtilityClasses();

  return (
    <div className={classes.root}>
      <div className={classes.spinnerLoading} />
    </div>
  );
};

export default Spinner;
