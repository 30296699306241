import { toAbsoluteUrl } from '~utils/commons';

export const QUESTIONS_INFO = [
  {
    id: 'working-in-the-gig-economy',
    iconUrl: toAbsoluteUrl('/icons/hand-holding-dollar.svg'),
  },
  {
    id: 'worried-about-inflation',
    iconUrl: toAbsoluteUrl('/icons/chart-line-up.svg'),
  },
  {
    id: 'need-to-send-money-overseas',
    iconUrl: toAbsoluteUrl('/icons/earth-africa.svg'),
  },
  {
    id: 'dont-have-a-bank-account',
    iconUrl: toAbsoluteUrl('/icons/bank.svg'),
  },
  {
    id: 'doing-cross-border-business',
    iconUrl: toAbsoluteUrl('/icons/flag.svg'),
  },
  {
    id: 'trading-crypto-currencies',
    iconUrl: toAbsoluteUrl('/icons/wallet.svg'),
  },
];
