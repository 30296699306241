import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import Typography from '../Typography';
import { DETAILS_INFO } from './data';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.detailsSectionRoot],
    item: [styles.detailsSectionItem],
    itemIcon: [styles.detailsSectionItemIcon],
    itemInfo: [styles.detailsSectionItemInfo],
    itemInfoTitle: [styles.detailsSectionItemInfoTitle],
    itemInfoDescription: [styles.detailsSectionItemInfoDescription],
  };

  return composeClasses(slots);
};

const DetailsSection = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useUtilityClasses();

  return (
    <section className={classes.root}>
      {DETAILS_INFO.map(({ id }) => (
        <div key={`detail-${id}`} className={classes.item}>
          <SVG
            src={toAbsoluteUrl(`/icons/${id}.svg`)}
            title={t(`details.${id}.title`)}
            className={classes.itemIcon}
          />

          <div className={classes.itemInfo}>
            <Typography component="h3" className={classes.itemInfoTitle}>
              {t(`details.${id}.title`)}
            </Typography>

            <Typography component="p" className={classes.itemInfoDescription}>
              {t(`details.${id}.description`)}
            </Typography>
          </div>
        </div>
      ))}
    </section>
  );
};

export default DetailsSection;
