import { ReactElement, ElementType } from 'react';
import classnames from 'classnames';
import { capitalize } from 'lodash';

import { composeClasses } from '~utils/commons';

import { TypographyProps, TypographyVariantMapping } from './types';

import styles from './styles.module.scss';

const useUtilityClasses = ({
  align,
  color,
  fontWeight,
  lineHeight,
  textTransform,
  variant,
  gutterBottom,
  noWrap,
  paragraph,
}: Required<Omit<TypographyProps, 'children' | 'component' | 'className'>>) => {
  const slots = {
    root: [
      styles.typographyRoot,
      align !== 'inherit' && styles[`typographyAlign${capitalize(align)}`],
      color !== 'inherit' && styles[`typographyColor${capitalize(color)}`],
      fontWeight !== 'inherit' &&
        styles[`typographyFontWeight${capitalize(fontWeight)}`],
      lineHeight !== 'inherit' &&
        styles[`typographyLineHeight${capitalize(lineHeight)}`],
      textTransform !== 'inherit' &&
        styles[`typographyTextTransform${capitalize(textTransform)}`],
      variant !== 'inherit' &&
        styles[`typographyVariant${capitalize(variant)}`],
      gutterBottom && styles.typographyGutterBottom,
      noWrap && styles.typographyNoWrap,
      paragraph && styles.typographyParagraph,
    ],
  };

  return composeClasses(slots);
};

const defaultVariantMapping: Record<TypographyVariantMapping, ElementType> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  body1: 'p',
  body2: 'p',
  caption: 'p',
  'caption-small': 'p',
  inherit: 'p',
};

const Typography = ({
  align = 'inherit',
  className,
  color = 'inherit',
  component,
  fontWeight = 'inherit',
  gutterBottom = false,
  lineHeight = 'inherit',
  noWrap = false,
  paragraph = false,
  textTransform = 'inherit',
  variant = 'inherit',
  ...rest
}: TypographyProps): ReactElement => {
  const Component =
    component || (paragraph ? 'p' : defaultVariantMapping[variant]) || 'span';

  const classes = useUtilityClasses({
    align,
    color,
    fontWeight,
    lineHeight,
    textTransform,
    variant,
    gutterBottom,
    noWrap,
    paragraph,
  });

  return (
    <Component className={classnames(classes.root, className)} {...rest} />
  );
};

export default Typography;
