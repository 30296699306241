export const composeClasses = <ClassKey extends string>(
  slots: Record<ClassKey, ReadonlyArray<string | false | undefined | null>>,
): Record<ClassKey, string> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const output: Record<ClassKey, string> = {} as any;

  Object.keys(slots).forEach(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    (slot: ClassKey) => {
      output[slot] = slots[slot]
        .reduce((acc, key) => {
          if (key) {
            acc.push(key);
          }
          return acc;
        }, [] as string[])
        .join(' ');
    },
  );

  return output;
};

export const toAbsoluteUrl = (pathName: string): string =>
  process.env.PUBLIC_URL + pathName;
