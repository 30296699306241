import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import Typography from '../Typography';
import { QUESTIONS_INFO } from './data';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.questionsSectionRoot],
    title: [styles.questionsSectionTitle],
    list: [styles.questionsSectionList],
    item: [styles.questionsSectionListItem],
    itemCard: [styles.questionsSectionListItemCard],
    itemCardIcon: [styles.questionsSectionListItemIcon],
    itemCardInfo: [styles.questionsSectionListItemCardInfo],
    itemCardInfoQuestion: [styles.questionsSectionListItemCardInfoQuestion],
    itemCardInfoAnswer: [styles.questionsSectionListItemCardInfoAnswer],
    polygonBgLeft: [styles.questionsSectionPolygonBgLeft],
    polygonBgRight: [styles.questionsSectionPolygonBgRight],
  };

  return composeClasses(slots);
};

const QuestionsSection = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useUtilityClasses();

  return (
    <section className={classes.root}>
      <Typography component="h2" className={classes.title}>
        {t('questions.title')}
      </Typography>

      <div className={classes.list}>
        {QUESTIONS_INFO.map(({ id, iconUrl }) => (
          <div key={`question-${id}`} className={classes.item}>
            <div className={classes.itemCard}>
              <SVG
                src={iconUrl}
                title={t(`questions.${id}.question`)}
                className={classes.itemCardIcon}
              />

              <div className={classes.itemCardInfo}>
                <Typography
                  component="h5"
                  className={classes.itemCardInfoQuestion}
                >
                  {t(`questions.${id}.question`)}
                </Typography>

                <Typography
                  component="p"
                  className={classes.itemCardInfoAnswer}
                >
                  {t(`questions.${id}.answer`)}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </div>

      <SVG
        src={toAbsoluteUrl('/images/bg-polygon-3.svg')}
        title="Background Polygon 3"
        className={classes.polygonBgLeft}
      />

      <SVG
        src={toAbsoluteUrl('/images/bg-polygon-4.svg')}
        title="Background Polygon 4"
        className={classes.polygonBgRight}
      />
    </section>
  );
};

export default QuestionsSection;
