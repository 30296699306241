import { ReactElement } from 'react';
import SVG from 'react-inlinesvg';

import { composeClasses, toAbsoluteUrl } from '~utils/commons';

import Link from '../Link';

import styles from './styles.module.scss';

const useUtilityClasses = () => {
  const slots = {
    root: [styles.actionPresentationRoot],
    link: [styles.actionPresentationLink],
    kmImg: [styles.actionPresentationLinkKmImg],
    tetheImg: [styles.actionPresentationLinkTetheImg],
  };

  return composeClasses(slots);
};

const ActionPresetation = (): ReactElement => {
  const classes = useUtilityClasses();

  return (
    <section className={classes.root}>
      <Link
        href="https://kriptonmarket.com/"
        target="blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <SVG
          src={toAbsoluteUrl('/icons/km-isologo.svg')}
          title="KM Isologo"
          className={classes.kmImg}
        />

        <SVG
          src={toAbsoluteUrl('/icons/tether-isologo.svg')}
          title="Tether Isologo"
          className={classes.tetheImg}
        />
      </Link>
    </section>
  );
};

export default ActionPresetation;
